import { ExtendsIconProps } from './BaseIcon';
import Lottie from 'react-lottie';
const animationData = require('./json/Index.json')

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export default function IndexIcon(props: ExtendsIconProps) {
  return (<Lottie options={defaultOptions} />);
}
