let started = false;
export const tryStartBgMusic = () => {
  if (!started) {
    if (document.querySelector('audio#bgAudio')) {
      try {
        (document.querySelector('audio#bgAudio') as any).play().then(() => { started = true });
      } catch (e) {

      }
    }
  }
}
export const tryStartSoundClick = () => {
  if (document.querySelector('audio#sound-click')) {
    try {
      (document.querySelector('audio#sound-click') as any).play().then(() => { });
    } catch (e) {

    }
  }
}
document.addEventListener('touchstart', tryStartBgMusic)
document.addEventListener('mousedown', tryStartBgMusic)
document.addEventListener('click', tryStartBgMusic)
document.addEventListener('keydown', tryStartBgMusic)
