import React, { } from 'react';
import { ReactComponent as HistoryBarSvg } from '../../assets/svg_icon/historybar.svg';
import './HistoryBar.scss';

export default function HistoryBar(props: React.Props<any>) {
  return (
    <div className="history-bar">
      <HistoryBarSvg></HistoryBarSvg>
      {props.children}
    </div>
  );
}
