import React, { } from 'react';
import { ReactComponent as StudentBarSvg } from '../../assets/svg_icon/studentbar.svg';
import { ReactComponent as AwardbuttonSvg } from '../../assets/svg_icon/awardbutton.svg';
import { ReactComponent as QuestionbuttonSvg } from '../../assets/svg_icon/questionbutton.svg';
import { ReactComponent as SettingbuttonSvg } from '../../assets/svg_icon/settingbutton.svg';
import { tryStartSoundClick } from '../../libs/sounds';
import './Header.scss';

export default function Header() {
  return (
    <header>
      <div className="student-bar">
        <StudentBarSvg></StudentBarSvg>
        <span className="class">班級: 4B</span>
        <span className="star">10</span>
        <div className="news" style={({ '--line': 3 } as React.CSSProperties)}>
          <div className="news-1">各位同學 1<br />各位同學 2<br />各位同學 3</div>
          <div className="news-2">各位同學 1<br />各位同學 2<br />各位同學 3</div>
        </div>
      </div>
      <div className="buttons">
        <a className="aspect-box" onClick={() => tryStartSoundClick()}>
          <QuestionbuttonSvg />
        </a>
        <a className="aspect-box" onClick={() => tryStartSoundClick()}>
          <AwardbuttonSvg />
        </a>
        <a className="aspect-box" onClick={() => tryStartSoundClick()}>
          <SettingbuttonSvg />
        </a>
      </div>
    </header>
  );
}
