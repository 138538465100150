import React, { useEffect } from 'react';

const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

interface WindowProps {
  onWindowResize?: () => void
}

export default function Window(props: WindowProps) {
  useEffect(() => {
    const addEventListeners = () => {
      window.addEventListener("resize", () => {
        onWindowResize()
        props.onWindowResize && props.onWindowResize();
      });
    };

    const removeEventListeners = () => {
      window.removeEventListener("resize", () => {
        onWindowResize()
        props.onWindowResize && props.onWindowResize();
      });
    };

    if (typeof navigator !== "undefined" && isMobile()) {

    } else {
      onWindowResize();
      props.onWindowResize && props.onWindowResize();
      addEventListeners();
      return () => removeEventListeners();
    }
  }, []);

  const onWindowResize = () => {
    // @ts-ignore
    document.querySelector('#root').style.setProperty('--zoom', window.devicePixelRatio);
    // @ts-ignore
    document.querySelector('#root').style.setProperty('--size-by-aspect-ratio', (window.innerWidth / window.innerHeight > 16 / 9) ? '1' : '2');
    document.body.classList.remove('zoom-gt-1', 'zoom-lte-1');
    document.body.classList.add(window.devicePixelRatio > 1 ? 'zoom-gt-1' : 'zoom-lte-1');
  }

  if (typeof navigator !== "undefined" && isMobile()) return null;

  return (
    <React.Fragment />
  );
};
