import React, { useState, useEffect, useRef } from 'react';

import './App.scss';
import HomePage from './components/icons/Index';
import PaperMaking from './components/icons/papermaking';
import WarringStates from './components/icons/warringstates';
import ChineseMilitary from './components/icons/chinesemilitary';
import EmpressIcon from './components/icons/EmpressIcon';
// import Cursor from './components/ui/Cursor';
import Window from './components/ui/Window';
// import MyDatGui from './components/ui/MyDatGui';
import Header from './components/project-ui/Header';
import Footer from './components/project-ui/Footer';
import { tryStartBgMusic, tryStartSoundClick } from './libs/sounds';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Controller } from 'swiper/core';
// import historybararrowbutton_right from './assets/svg_icon/historybararrowbutton_right.svg';
// import historybararrowbutton_left from './assets/svg_icon/historybararrowbutton_left.svg';

// import { ReactComponent as Icon1 } from './assets/svg_icon/icon1preview.svg';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation, Controller]);

function App() {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  if (firstSwiper && secondSwiper) {
    if (firstSwiper) {
      console.log('firstSwiper', firstSwiper);
      (firstSwiper as any).updateSlides();
      (firstSwiper as any).updateSize();
      (firstSwiper as any).controller.control = secondSwiper;
    }
    if (secondSwiper) {
      (secondSwiper as any).controller.control = firstSwiper;
    }
  }

  const swiperUpdate = () => {
    console.log(1);
    if (firstSwiper) {
      (firstSwiper as any).updateSlides();
      (firstSwiper as any).updateSize();
    }
  }

  return (
    <React.Fragment>
      <div className="App">
        <HomePage loop={true} autoplay={true} />

        {/* <div className="aspect-box">
  <p>123</p>
</div> */}
        <Header />

        <div className="main-swiper">
          <Swiper
            onSwiper={(swiper: any) => {
              setFirstSwiper(swiper);
            }}
            spaceBetween={0}
            slidesPerView={3}
            navigation
          >
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><ChineseMilitary /></div></SwiperSlide>
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><PaperMaking /></div></SwiperSlide>
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><WarringStates /></div></SwiperSlide>
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><EmpressIcon /></div></SwiperSlide>
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><ChineseMilitary /></div></SwiperSlide>
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><PaperMaking /></div></SwiperSlide>
            <SwiperSlide className="aspect-box" onClick={() => tryStartSoundClick()}><div><WarringStates /></div></SwiperSlide>
          </Swiper>
        </div>

        <Footer>
          <Swiper
            onSwiper={(swiper: any) => {
              setSecondSwiper(swiper);
            }}
            spaceBetween={0}
            slidesPerView={7}
            navigation
          >
            {/* <SwiperSlide>1</SwiperSlide>
            <SwiperSlide>2</SwiperSlide>
            <SwiperSlide>2</SwiperSlide>
            <SwiperSlide>2</SwiperSlide>
            <SwiperSlide>2</SwiperSlide> */}
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={1} /></SwiperSlide>
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={2} /></SwiperSlide>
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={3} /></SwiperSlide>
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={4} /></SwiperSlide>
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={5} /></SwiperSlide>
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={6} /></SwiperSlide>
            <SwiperSlide className="footer-slide" onClick={() => tryStartSoundClick()}><FooterSwiperSlide imgIndex={7} /></SwiperSlide>
          </Swiper>
        </Footer>
      </div>

      {/* <Cursor /> */}
      <Window onWindowResize={() => swiperUpdate()} />
      {/* <MyDatGui /> */}

      <audio controls id="bgAudio" src="/assets/ui/bgm/24603855.mp3" preload='auto' loop hidden />
      <audio controls id="sound-click" src="/assets/ui/mouse/MouseClick1.mp3" preload='auto' hidden />
    </React.Fragment >
  );
}

function FooterSwiperSlide(props: { imgIndex: number }) {
  return (
    <div className="footer-slide-wrapper">
      <img className="popup" src={require(`./assets/png_icon/main-footer/character${props.imgIndex}_popup.png`).default} />
      <img className="preview" src={require(`./assets/png_icon/main-footer/character${props.imgIndex}_preview.png`).default} />
      <img className="line" src={require(`./assets/png_icon/main-footer/line.png`).default} />
    </div>
  );
}

export default App;
