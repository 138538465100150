import React, { } from 'react';
import BattleIcon from '../icons/battleicon';
import HistoryIcon from '../icons/historyicon';
import HistoryBar from '../project-ui/HistoryBar';
import { tryStartSoundClick } from '../../libs/sounds';

import './Footer.scss';

export default function Footer(props: React.Props<any>) {
  return (
    <footer>
      <button onClick={() => tryStartSoundClick()}><HistoryIcon className="footer-icon footer-icon-left" /></button>
      <HistoryBar>{props.children}</HistoryBar>
      <button onClick={() => tryStartSoundClick()}><BattleIcon className="footer-icon footer-icon-right" /></button>
    </footer>
  );
}
