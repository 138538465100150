import React, { useRef } from 'react';
import Lottie from 'react-lottie';
import { requireAnimationData, defaultOptions, iProps, LottieDiv } from './BaseIcon';

const animationData = requireAnimationData('papermaking', 'PaperMakingIcon');

export default function IndexIcon(props: iProps) {
  const lottieDiv = useRef(null);

  const enter = () => {
    if (null !== lottieDiv.current) {
      // @ts-ignore
      (lottieDiv.current as LottieDiv).anim.playSegments([120, 240], true);
    }
  }
  const leave = () => {
    if (null !== lottieDiv.current) {
      // @ts-ignore
      (lottieDiv.current as LottieDiv).anim.goToAndStop(0);
    }
  }

  return (
    <div className={`lottie-svg ${props.className ?? ''}`} onMouseEnter={enter} onMouseLeave={leave}>
      <Lottie options={{ ...defaultOptions, animationData }} isStopped={true} ref={lottieDiv} />
    </div>
  );
}
